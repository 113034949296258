import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  NgZone,
  OnDestroy,
  Output,
  Signal,
  signal,
} from '@angular/core';
import { Params, RouterLink, RouterModule } from '@angular/router';
import { AkitaStartingPricesQuery } from '@app/akita/api/starting-prices/state/starting-prices.query';
import { AkitaStartingPricesService } from '@app/akita/api/starting-prices/state/starting-prices.service';
import { AkitaRouterQuery } from '@app/akita/router/state/router.query';
import { AkitaScreenQuery } from '@app/akita/screen/state/screen.query';
import { ScreenSizeInfoState } from '@app/akita/screen/state/screen.store';
import {
  AllowedDeliveryCountries,
  LIST_OF_ALLOWED_DELIVERY_COUNTRY_CODES,
} from '@app/shared/data/delivery-countries.data';
import { ApiError } from '@app/shared/models/api/api-error.model';
import { StartingPriceModel } from '@app/shared/models/api/starting-price.model';
import { map, Observable, of, Subject, Subscription, takeUntil, tap } from 'rxjs';
import { ArrowRight1IconComponent } from '../../../shared/components/icons/generated/arrow-right-1/arrow-right-1.component';
import { ArrowLeft1IconComponent } from '../../../shared/components/icons/generated/arrow-left-1/arrow-left-1.component';
import { AsyncPipe } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { BurguerMenuIconComponent } from '@app/shared/components/icons/generated/burguer-menu/burguer-menu.component';
import { imagesMockData } from './images.mock-data';
import { RemoveIconComponent } from '../../../shared/components/icons/generated/remove/remove.component';
import { AkitaRemoteConfigQuery } from '@app/akita/api/remote-config/state/remote-config.query';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.template.html',
  styleUrls: ['./navigation-bar.style.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslocoModule,
    RouterLink,
    RouterModule,
    ArrowLeft1IconComponent,
    ArrowRight1IconComponent,
    BurguerMenuIconComponent,
    AsyncPipe,
    RemoveIconComponent,
  ],
})
export class NavigationBarComponent implements OnDestroy {
  @Input() public mobileMode?: boolean | null = false;
  @Input() public gridMode?: boolean | null = false;
  @Input() public pillMode?: boolean | null = false;
  @Input() public centerMode?: boolean | null = true;

  public readonly biggerThan$: Observable<ScreenSizeInfoState>;
  public readonly isRtlLayout$: Observable<boolean>;

  public enableBuybackFeature: Signal<boolean | null> = signal(false);

  public startingPrices$: Observable<Array<StartingPriceModel>>;
  public readonly fetchingStartingPrices$: Observable<boolean>;
  public readonly startingPricesError$: Observable<ApiError | null>;

  public brands$: Observable<Array<string>>;
  public laptopBrands$: Observable<Array<string>>;

  public iPhones$: Observable<Array<StartingPriceModel>>;
  public macBooks$: Observable<Array<StartingPriceModel>>;
  public iPads$: Observable<Array<StartingPriceModel>>;
  public airPods$: Observable<Array<StartingPriceModel>>;

  public tablets$: Observable<Array<StartingPriceModel>>;
  public watches$: Observable<Array<StartingPriceModel>>;
  public samsung$: Observable<Array<StartingPriceModel>>;
  public laptops$: Observable<Array<StartingPriceModel>>;
  public accessories$: Observable<Array<StartingPriceModel>>;
  public earbuds$: Observable<Array<StartingPriceModel>>;
  public headphones$: Observable<Array<StartingPriceModel>>;
  public gaming$: Observable<Array<StartingPriceModel>>;

  public readonly phoneSearchFragment$: Observable<Array<string>>;
  public country: string;

  public showDropDown: string | null;
  public showAllProducts: boolean | null;
  public showLaptops: string | null;

  public sharedImages: Signal<any | null> = signal(imagesMockData);

  public readonly subscriptions: Subscription;
  private readonly destroySubject: Subject<void>;

  public readonly queryParamsToKeep$: Observable<Params>;
  // public phoneProductFragment: Array<string>;

  @Output() public readonly itemSelected: EventEmitter<boolean>;

  constructor(
    private readonly zone: NgZone,
    private readonly akitaRouterQuery: AkitaRouterQuery,
    private readonly akitaStartingPricesQuery: AkitaStartingPricesQuery,
    private readonly akitaStartingPricesService: AkitaStartingPricesService,
    private readonly akitaScreenQuery: AkitaScreenQuery,
    private readonly akitaRemoteConfigQuery: AkitaRemoteConfigQuery,
    private readonly eRef: ElementRef
  ) {
    this.destroySubject = new Subject();
    this.subscriptions = new Subscription();

    this.biggerThan$ = this.akitaScreenQuery.selectBiggerThan();
    this.isRtlLayout$ = this.akitaRouterQuery.selectIsRtlLayout();

    this.enableBuybackFeature = toSignal(
      this.akitaRemoteConfigQuery.selectEnableBuybackFeature(),
      {
        initialValue: false,
      }
    );

    this.itemSelected = new EventEmitter();

    this.country = this.validateCountryCode(akitaRouterQuery.country);
    this.showDropDown = null;
    this.showLaptops = null;
    this.showAllProducts = false;

    this.queryParamsToKeep$ = this.akitaRouterQuery.selectQueryParamsToKeep();

    this.phoneSearchFragment$ = this.akitaRouterQuery.selectPhoneSearchFragment();

    this.subscriptions.add(
      this.akitaRouterQuery
        .selectCountry()
        .pipe(
          map((value) => (value = this.validateCountryCode(value))),
          tap((value) => {
            if (this.country !== value) {
              this.country = value;
              this.subscriptions.add(
                this.akitaStartingPricesService.findStartingPricesAsync(value)
              );
            }
          }),
          takeUntil(this.destroySubject)
        )
        .subscribe({
          next: () => {},
          error: () => {},
        })
    );

    this.startingPrices$ = this.akitaStartingPricesQuery.selectStartingPrices();
    this.fetchingStartingPrices$ =
      this.akitaStartingPricesQuery.selectFetchingStartingPrices();
    this.startingPricesError$ = this.akitaStartingPricesQuery.selectStartingPricesError();

    this.iPhones$ = this.akitaStartingPricesQuery.selectIPhones();
    this.macBooks$ = this.akitaStartingPricesQuery.selectMacBooks();
    this.iPads$ = this.akitaStartingPricesQuery.selectIPads();
    this.airPods$ = this.akitaStartingPricesQuery.selectAirPods();
    this.tablets$ = this.akitaStartingPricesQuery.selectTablets();
    this.watches$ = this.akitaStartingPricesQuery.selectWatches();
    this.samsung$ = this.akitaStartingPricesQuery.selectSamsung();
    this.accessories$ = this.akitaStartingPricesQuery.selectAccessories();
    this.earbuds$ = this.akitaStartingPricesQuery.selectEarbuds();
    this.headphones$ = this.akitaStartingPricesQuery.selectHeadphones();
    this.gaming$ = this.akitaStartingPricesQuery.selectGaming();
    this.laptops$ = of([]);

    this.brands$ = this.akitaStartingPricesQuery.selectBrands();
    this.laptopBrands$ = this.akitaStartingPricesQuery.selectLaptopsBrands();

    if (this.akitaRouterQuery.isBrowser) {
      this.zone.runOutsideAngular(this.fetchStartingPricesInBG.bind(this));
    } else {
      this.fetchStartingPricesInBG();
    }
  }

  @HostListener('document:click', ['$event'])
  public clickout(event: any) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      // clicked outside the element
      this.showDropDown = null;
      this.showLaptops = null;
      this.showAllProducts = false;
    }
  }

  private fetchStartingPricesInBG(): void {
    /* this.subscriptions.add(
      this.akitaStartingPricesService.findStartingPricesAsync(this.country)
    ); */
  }

  public validateCountryCode(country: string): string {
    if (country) {
      if (
        LIST_OF_ALLOWED_DELIVERY_COUNTRY_CODES.indexOf(
          `${country || ''}`.toUpperCase() as AllowedDeliveryCountries
        ) === -1
      ) {
        country = 'SA'; // fallback
      }
    }
    return country;
  }

  public toogleAllProducts(): void {
    if (this.showAllProducts) {
      this.showAllProducts = false;
    } else {
      this.showAllProducts = true;
    }
  }

  public closeDropDown(): void {
    this.showDropDown = null;
    this.showLaptops = null;
    this.showAllProducts = false;
  }

  public openDropDown(selected: string): void {
    if (this.showDropDown !== selected) {
      this.showDropDown = selected;
    } else {
      this.showDropDown = null;
    }
    this.showLaptops = null;
  }

  public getLaptops(selected: string): void {
    this.showLaptops = selected;

    this.laptops$ = this.akitaStartingPricesQuery.selectLaptops(selected);
  }

  public productFragment(id: number | null): Array<string> {
    return this.akitaRouterQuery.getProductUrlFragment(`${id}`);
  }

  public onItemSelected(): void {
    this.showDropDown = null;
    this.showLaptops = null;
    if (this.mobileMode) {
      this.itemSelected.emit(true);
    }
  }

  public getSearchParams(category?: string | null, brand?: string | null): Params {
    const searchParams = this.akitaRouterQuery.queryParamsToKeep;

    if (category) {
      searchParams.category = category;
    }
    if (brand) {
      searchParams.brands = brand;
    }

    return searchParams;
  }

  public getCategoryCollectionFragment(cat: string): Array<string> | string {
    const fragment = this.akitaRouterQuery.homeUrlFragment;
    fragment.push('c');
    fragment.push('category');
    fragment.push(cat.toLowerCase());
    return fragment;
  }

  public getModelCollectionFragment(cat: string): Array<string> | string {
    const fragment = this.akitaRouterQuery.homeUrlFragment;
    fragment.push('c');
    fragment.push('model');
    fragment.push(cat.toLowerCase());
    return fragment;
  }

  public getBestDealsFragment(): Array<string> | string {
    const fragment = this.akitaRouterQuery.homeUrlFragment;
    fragment.push('best-deals');
    return fragment;
  }

  public getProductSearchFragment(): Array<string> | string {
    const fragment = this.akitaRouterQuery.homeUrlFragment;
    fragment.push('products');
    return fragment;
  }

  public getBuybackFragment(): Array<string> | string {
    const fragment = this.akitaRouterQuery.homeUrlFragment;
    fragment.push('buyback');
    return fragment;
  }

  public trackByModel(index: number, category?: any | null): string {
    return (category as any)?.id || category || `${index}`;
  }

  public ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }
}

import languages from '../assets/i18n/languages.json';
import buildMetadata from '../assets/metadata.json';

export const environment = {
  name: 'dev',
  domain: 'https://dev.popsy.app/',
  app: {
    version: buildMetadata.version,
    commit: buildMetadata.commit,
    branch: buildMetadata.branch,
  },
  inServer: false,
  production: false,
  enableAnalitycs: true,
  debugAnalitycs: false,
  reportToSentry: true,
  sentryAskFeedback: false,
  serviceWorker: true,
  enableHotjar: false,
  enableOmnisend: false,
  enableSmartLook: false,
  api: {
    protocol: 'https://',
    url: 'api-staging.popsy.app',
    port: 443,
    path: '/api',
    version: '/v1',
  },
  blogApi: {
    protocol: 'https://',
    url: 'blog.popsy.app',
    port: 443,
    path: '/wp-json',
    version: '/v2',
  },
  firebase: {
    apiKey: 'AIzaSyD91zJcjmocLffLw1rd1mjMRNWl4vcQvjI',
    authDomain: 'popsygae-dev.firebaseapp.com',
    databaseURL: 'https://popsygae-dev.firebaseio.com',
    projectId: 'popsygae-dev',
    storageBucket: 'api-dev.popsy.app',
    messagingSenderId: '693805660182',
    appId: '1:693805660182:web:b03cdf83c1578105359d37',
    measurementId: 'G-XSLLL760JX',
  },
  stripe: {
    country: {
      br: 'pk_test_53pmof2Ok18Q3DC21W6Zs7to00XkMPBBLU',
      us_account: 'pk_test_BaKkPxnIuA6qCRMooo3ZTji000ybC0yfyr', // TODO Implement API PublicKey for Stripe too
      us: 'pk_test_51MhflFCWBKdjR5CgiBfTeJDp0Iktaub136nBxEW8eKXZX0YTDfwHVDM8vAQE4SxXNFnWMhwtTr8DJbsd9Hk3c3XW00U4iSeNjR',
    },
  },
  mercadoPago: {
    appId: '7821591973430233',
    key: 'TEST-d2d6ea78-ef31-4bbd-82f4-bbaf8ed41a92',
    accessToken:
      'TEST-7821591973430233-032211-4d7fa7ea502c00c4da7da13142a3d5f3-732416093',
  },
  tabby: {
    pubKey: 'pk_test_7c8549a3-1a2e-4855-b790-15f07512f54e',
  },
  tamara: {
    pubKey: '43164d09-ba18-45ea-bb4e-09cde9fa8c8d',
  },
  checkout: {
    pubKey: 'pk_sbox_7s3lbhhllye6oyhy7nl6hha7ny2',
  },
  moip: {
    pubKey: `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA8P6nFCw7XsZfoKGAk95C
IxnX+oGOXRbpKChxSElqfgd0Dx/WLy2/K+85r6R9UVQLGzET7MBB0b/ldzKoZ0uC
0IeWUX+ZXtxFwdBcs7xClm2bFTP4emnjccUdOUDLpzl8V5uF0w3xWPT1nM2KmnaE
ILaQ3SS2M8Z0cJSFGZfFFU8hgo788YTZcIXa4G7i6Z94BXEymJdbbr1ASO5Nf00F
ZJ0DBosn2Y0o/WKzHb+Me7j3bOJ2SKNzI+SjSiNk2voPOdQQnZVB8NHEPJQ6GfBO
t8WRbLhNWHlZLbuP8OGiUkYx96SGzxnVsdXtoSl5lqzOMRMVqbDui9wxW2fz1ptK
vQIDAQAB
-----END PUBLIC KEY-----`,
  },
  apple: {
    clientId: 'app.popsy.buysell.staging.service',
    baseURL: 'https://staging.popsy.app',
  },
  google: {
    clientId: '939751172442-8gglfp40l0cp43nq1lpcp0slqnkvehpo.apps.googleusercontent.com',
    baseURL: 'https://staging.popsy.app',
    googlePayMerchantId: 'BCR2DN6T5PWJRHKQ',
  },
  debugMode: true,
  hmr: false,
  languageCode: 'en',
  languages: languages,
  fbAppId: '202741537078980',
  fbAppVersion: 'v7.0',
  gmapsApiKey: 'AIzaSyCHJ2M3LT9UvWqfPSg2TTW-C7Lkwh3eJ-0',
  sentryUrl: 'https://7271105c2e4444a8928e7094fff7ab61@sentry.io/1212910',
  shopify: {
    US: { apiToken: 'e08609a1b815f88e555ee7ea622009c6', domain: 'shop.popsy.app' },
    AE: {
      apiToken: '36daa96b3d8816b7d4894c97ee497db8',
      domain: 'popsyuae.myshopify.com',
    },
  },
};

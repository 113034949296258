import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Observable, distinctUntilChanged, map } from 'rxjs';
import { AkitaBuybackState, BuyBackStepOption } from '../models/buyback.state';
import { AkitaBuybackStore } from './buyback.store';
import { AkitaProductsQuery } from '../../products/state/products.query';
import {
  BuybackSearchFilters,
  generateBuybackFiltersID,
} from '../models/buyback-search.filters.model';
import { BuybackOfferModel } from '../models/buyback.offer.model';
import { Params } from '@angular/router';
import { BuybackOrderRequestInfo } from '../models/buyback-order-request-info.model';
import { AkitaAuthQuery } from '../../auth/state/auth.query';
import { UserDetails } from '../../addresses/models/user-details.model';
import { BuybackOrderModel } from '../models/buyback-order.model';

@Injectable({ providedIn: 'root' })
export class AkitaBuybackQuery extends Query<AkitaBuybackState> {
  constructor(
    protected readonly store: AkitaBuybackStore,
    private readonly akitaProductsQuery: AkitaProductsQuery,
    private readonly akitaAuthQuery: AkitaAuthQuery
  ) {
    super(store);
  }

  public get products(): any | null {
    const country = this.akitaProductsQuery.country;
    const products = this.getValue().products;
    const countryProducts = products[country] || null;
    return countryProducts;
  }

  public get step(): string | null {
    return this.getValue().form.step;
  }

  public get stepIndex(): number {
    return this.getValue().form.stepIndex;
  }

  public get flowSteps(): Array<BuyBackStepOption> {
    return this.getValue().form.flowSteps;
  }

  public get formValues(): Array<BuyBackStepOption> {
    return this.getValue().form.formValues;
  }

  public getStepValue(step: string): string | number | null {
    const flowSteps = this.flowSteps;
    const findFlowStep = flowSteps.find((flowStep) => flowStep[step] !== undefined);
    const currentStepValue = findFlowStep?.[step] || null;

    return currentStepValue;
  }

  public getFormValue(step: string): string | number | null {
    const formValues = this.getValue().form.formValues;
    const findFormValue = formValues.find((formValue) => formValue[step] !== undefined);
    const currentFormValue = findFormValue?.[step] || null;

    return currentFormValue;
  }

  public getFlowStepOptions(step: string): Array<string> {
    const filters = {
      ...new BuybackSearchFilters(),
      country: this.akitaProductsQuery.country,
      category: [...[], this.getValue().form.category || '-'],
    };
    console.log(this.akitaProductsQuery.country);
    if (this.getFormValue('brand') && step !== 'brand') {
      filters.brand = [this.getFormValue('brand') as string];
    }
    if (this.getFormValue('model') && step !== 'model' && step !== 'brand') {
      filters.model = [this.getFormValue('model') as string];
    }
    const path = generateBuybackFiltersID(filters);
    console.log('path', path, filters, this.products);
    const productSearch = this.products || [];
    const currentSearch = productSearch[path || '-'];
    const currentStepOptions = currentSearch?.options[step] || null;

    console.log('currentStepOptions', step, productSearch[path], currentStepOptions);
    return currentStepOptions;
  }

  public selectFlowStepOptions(step: string): Observable<Array<string>> {
    return this.select().pipe(
      map(() => this.getFlowStepOptions(step)),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public selectProductOffers(
    filters: BuybackSearchFilters
  ): Observable<Array<BuybackOfferModel> | null> {
    const country = this.akitaProductsQuery.country;
    return this.select().pipe(
      map((state: AkitaBuybackState) => {
        const path = generateBuybackFiltersID(filters);
        const productSearch = state.products[country] || [];
        const currentSearch = productSearch[path || '-'];
        const currentOffers = currentSearch?.offers || null;

        return currentOffers;
      }),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public selecProductOptions(filters: BuybackSearchFilters): Observable<Params | null> {
    const country = this.akitaProductsQuery.country;
    const path = generateBuybackFiltersID(filters);
    return this.select().pipe(
      map((state: AkitaBuybackState) => {
        const productSearch = state.products[country] || [];
        const currentSearch = productSearch[path || '-'];
        const currentOptions = currentSearch?.options || null;

        return currentOptions;
      }),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public selectStep(): Observable<string | null> {
    return this.select().pipe(
      map((state: AkitaBuybackState) => state.form.step),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public selectModel(): Observable<string | null> {
    return this.select().pipe(
      map((state: AkitaBuybackState) => state.form.model),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public selectCategory(): Observable<string | null> {
    return this.select().pipe(
      map((state: AkitaBuybackState) => state.form.category),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public selectModelImage(model: string): Observable<string | null> {
    return this.select().pipe(
      map((state: AkitaBuybackState) => {
        const products = state.products[this.akitaProductsQuery.country]?.[model];
        const offers = products?.offers || null;

        return offers?.[0]?.images?.[0] || null;
      }),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public selectIsSubmitting(): Observable<boolean> {
    return this.select().pipe(
      map((state: AkitaBuybackState) => state.isSubmitting),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public selectStepIndex(): Observable<number> {
    return this.select().pipe(
      map((state: AkitaBuybackState) => state.form.stepIndex),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public selectFlowSteps(): Observable<Array<BuyBackStepOption>> {
    return this.select().pipe(
      map((state: AkitaBuybackState) => state.form.flowSteps),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public selectFormValues(): Observable<Array<BuyBackStepOption>> {
    return this.select().pipe(
      map((state: AkitaBuybackState) => state.form.formValues),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public selectCurrentStepValue(): Observable<string | number | null> {
    return this.select().pipe(
      map((state: AkitaBuybackState) => {
        const step = state.form.step || null;
        const stepIndex = state.form.stepIndex || 0;
        const flowSteps = state.form.flowSteps;
        const currentFlowStep = flowSteps[stepIndex];
        const currentStepValue = step ? currentFlowStep[step || ''] : null;

        return currentStepValue;
      }),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public selectCurrentStepOptions(): Observable<Array<string>> {
    const country = this.akitaProductsQuery.country;
    return this.select().pipe(
      map((state: AkitaBuybackState) => {
        const category = state.form.category;
        const step = state.form.step || '-';
        const productSearch = state.products[country] || [];
        const currentSearch = productSearch[category || '-'];
        const currentStepOptions = currentSearch?.options[step] || null;
        return currentStepOptions;
      }),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public selectFetchingProducts(): Observable<boolean> {
    return this.select().pipe(
      map((state: AkitaBuybackState) => state.fetchingProducts),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public selectErrorFetchingProducts(): Observable<any | null> {
    return this.select().pipe(
      map((state: AkitaBuybackState) => state.erroFetchingProducts),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public selectBuyBackOrder(): Observable<BuybackOrderModel | null> {
    return this.select().pipe(
      map((state: AkitaBuybackState) => state.order),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public selectBuyBackUserOrders(): Observable<Array<BuybackOrderModel>> {
    return this.select().pipe(
      map((state: AkitaBuybackState) => state.userOrders || []),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public selectErrorSubmittingOffer(): Observable<any | null> {
    return this.select().pipe(
      map((state: AkitaBuybackState) => state.errorSubmittingOffer),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public generateBuybackOrderRequest(): BuybackOrderRequestInfo {
    const state = this.getValue();

    const country = this.akitaProductsQuery.country;
    const language = this.akitaProductsQuery.language;

    const selectedOffer = state.selectedOffer;
    const userId = this.akitaAuthQuery.user?.id;
    const userDetails = state.userDetails || new UserDetails();
    // eslint-disable-next-line max-len
    const address = `${userDetails?.address.address} ${userDetails.address.addressDetails} ${userDetails?.address.city}, ${userDetails.address.postalCode}`;
    const iban = 'iban';

    return {
      product_id: selectedOffer?.pid || '',
      customer_id: userId || '',
      email: userDetails.email,
      name: userDetails.fullName,
      phone: userDetails.contactDetails.display,
      address: address,
      iban: iban,
      language: language,
      country: country,
      client: 'WEB',
    };
  }
}
